<template>
  <el-dialog :destroy-on-close="true" :title="'配置'+(row.level==1?'三级菜单':'二级菜单')" :visible.sync="show" :lock-scroll="false" :before-close="dialogClose" :close-on-click-modal="false" width="520px">
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="菜单名称" prop="label">
        <el-input style="width:300px;" placeholder="请输入菜单名称" v-model="form.label" disabled></el-input>
      </el-form-item>

      <el-form-item :label="row.level==1?'三级菜单':'二级菜单'">
        <el-select v-model="form.childrenId" multiple placeholder="请选择" style="width:300px;">
          <el-option v-for="item in list" :key="item.id" :label="item.label" :value="String(item.id)"></el-option>
        </el-select>
      </el-form-item>
      <el-button @click="dialogClose">关闭</el-button>
      <el-button type="primary" v-debounce="onSubmit">保存</el-button>
    </el-form>
  </el-dialog>
</template>
<script>
import { getMobileMenuList, updateMobileMenu } from '@/api/appSetting'
import uploadFileOnly from '@/components/uploadFileOnly'
export default {
  name: 'AddLevel',
  props: ['show', 'row'],
  components: { uploadFileOnly },
  data() {
    return {
      list: [],
      form: {
        label: '',//菜单名称
        childrenId: '',//二级菜单
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getMobileMenuList({ level: Number(this.row.level) + 1 }).then(res => {
        this.list = res.data || []
        console.log(this.row)
        let list = []
        if (this.row && this.row.childrenId) {
          list = JSON.parse(JSON.stringify(this.row.childrenId))
          list = list.split(',')
        }
        console.log(this.row.childrenId)
        Object.assign(this.form, this.row)
        this.form.childrenId = list
      })
    },
    dialogClose() {
      this.$emit('update:show', false)
    },
    onSubmit() {
      let form = JSON.parse(JSON.stringify(this.form));
      form.childrenId = String(form.childrenId)
      let success = res => {
        console.log(res)
        if (res.code != 200) {
          this.$message.error('保存失败')
          return
        }
        this.$message.success('保存成功')
        this.$emit('success')
        this.dialogClose()
      }
      updateMobileMenu(form).then(success)
    },
  },
}
</script>
<style scoped>
.el-form-item {
  text-align: left;
}
.content-text {
  letter-spacing: 1px;
  color: #333333;
  margin: 0px 10px;
  font-size: 16px;
}
</style>