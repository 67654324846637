import axios from '@/utils/http';

//菜单管理
//添加移动端菜单
export function addMobileMenu(params) {
  return axios.post('/WlynMobileMenu/addMobileMenu', params);
}
//修改移动端菜单
export function updateMobileMenu(params) {
  return axios.post('/WlynMobileMenu/updateMobileMenu', params);
}
//获取移动菜单分页列表
export function getMobileMenuPage(params) {
  return axios.post('/WlynMobileMenu/getMobileMenuPage', params);
}
//获取全部移动菜单列表
export function getAllMobileMenuList(params) {
  return axios.get('/WlynMobileMenu/getAllMobileMenuList', {
    params
  });
}
//获取移动菜单列表
export function getMobileMenuList(params) {
  return axios.post('/WlynMobileMenu/getMobileMenuList', params);
}
//是否禁用
export function isDisable(params) {
  return axios.get('/WlynMobileMenu/isDisable', {
    params
  });
}
//删除移动端菜单
export function deleteMobileMenu(params) {
  return axios.get('/WlynMobileMenu/deleteMobileMenu', {
    params
  });
}

// 产品管理

//添加产品属性配置
export function addProductAttributeConfig(params) {
  return axios.post('/WlynProductAttributeConfig/addProductAttributeConfig', params);
}
//修改产品属性配置
export function updateProductAttributeConfig(params) {
  return axios.post('/WlynProductAttributeConfig/updateProductAttributeConfig', params);
}
//获取产品属性配置分页列表
export function getProductAttributeConfigPage(params) {
  return axios.post('/WlynProductAttributeConfig/getProductAttributeConfigPage', params);
}
//删除产品属性配置
export function deleteProductAttributeConfig(params) {
  return axios.get('/WlynProductAttributeConfig/deleteProductAttributeConfig', {
    params
  });
}
//是否禁用
export function isDisableProductAttributeConfig(params) {
  return axios.get('/WlynProductAttributeConfig/isDisable', {
    params
  });
}


//添加产品配置
export function addProductConfig(params) {
  return axios.post('/WlynProductConfig/addProductConfig', params);
}
//修改产品配置
export function updateProductConfig(params) {
  return axios.post('/WlynProductConfig/updateProductConfig', params);
}
//获取产品配置分页列表
export function getProductConfigPage(params) {
  return axios.post('/WlynProductConfig/getProductConfigPage', params);
}
//获取产品配置列表
export function getProductConfigList(params) {
  return axios.post('/WlynProductConfig/getProductConfigList', params);
}
//删除产品配置
export function deleteProductConfig(params) {
  return axios.get('/WlynProductConfig/deleteProductConfig', {
    params
  });
}
//是否禁用
export function isDisableProductConfig(params) {
  return axios.get('/WlynProductConfig/isDisable', {
    params
  });
}
//获取公众号版本
export function getVersion(params) {
  return axios.get('/WlynVersion/getVersion', {
    params
  });
}
//设置公众号版本
export function setVersion(params) {
  return axios.get('/WlynVersion/setVersion', {
    params
  });
}