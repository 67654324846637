<template>
  <el-dialog :destroy-on-close="true" :title="title" :visible.sync="isAddDialog" :lock-scroll="false" :before-close="dialogClose" :close-on-click-modal="false" width="520px">
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="菜单名称" prop="label" :rules="{required: true, message: '菜单名称不能为空', trigger: 'blur'}">
        <el-input style="width:300px;" placeholder="请输入菜单名称" v-model="form.label" v-if="type"></el-input>
        <div class="content-text" v-else>{{form.label}}</div>
      </el-form-item>
      <el-form-item label="菜单编码">
        <el-input style="width:300px;" placeholder="请输入菜单编码" v-model="form.code" v-if="type"></el-input>
        <div class="content-text" v-else>{{form.code}}</div>
      </el-form-item>
      <el-form-item label="菜单图标">
        <uploadFileOnly :imageUrl.sync="form.icon" :isDel.sync="type" />
      </el-form-item>
      <el-form-item label="菜单说明">
        <el-input style="width:300px;" placeholder="请输入菜单说明" v-model="form.notes" v-if="type"></el-input>
        <div class="content-text" v-else>{{form.notes}}</div>
      </el-form-item>
      <el-form-item label="菜单活动">
        <el-input style="width:300px;" placeholder="请输入菜单活动" v-model="form.activity" v-if="type"></el-input>
        <div class="content-text" v-else>{{form.sysAccount}}</div>
      </el-form-item>
      <el-form-item label="菜单级别" prop="level" v-if="!parentId">
        <el-radio-group v-model="form.level" :disabled="type!=1">
          <el-radio style="margin:5px;" :label="0">一级菜单</el-radio>
          <el-radio style="margin:5px;" :label="1">二级菜单</el-radio>
          <el-radio style="margin:5px;" :label="2">三级菜单</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="菜单地区">
        <el-select v-model="form.regionCode" multiple placeholder="请选择" style="width:300px;" :disabled="!type">
          <el-option v-for="item in list" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="菜单路径" prop="path" :rules="{required: true, message: '菜单路径不能为空', trigger: 'blur'}">
        <!-- <el-form-item label="菜单路径" prop="path"> -->
        <!-- multiple -->
        <el-select v-model="form.path" multiple filterable allow-create default-first-option placeholder="请选择菜单路径" v-if="type" style="width:300px;">
          <el-option v-for="item in productList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <!-- <el-input style="width:300px;" placeholder="请输入菜单路径" v-model="form.path" v-if="type"></el-input> -->
        <div class="content-text" v-else>{{form.path}}</div>
      </el-form-item>
      <el-form-item label="菜单排序" v-if="form.level==0" prop="sort" :rules="{required: true, message: '菜单排序不能为空', trigger: 'blur'}">
        <el-input style="width:300px;" placeholder="请输入菜单排序" v-model.number="form.sort" v-if="type"></el-input>
        <div class="content-text" v-else>{{form.sort}}</div>
      </el-form-item>
      <el-button @click="dialogClose">关闭</el-button>
      <el-button type="primary" v-debounce="onSubmit" v-if="type">保存</el-button>
    </el-form>
  </el-dialog>
</template>
<script>
import { addMobileMenu, updateMobileMenu, getProductConfigList } from '@/api/appSetting'
import { provinceList } from '@/utils/utils.js'
import uploadFileOnly from '@/components/uploadFileOnly'
export default {
  name: 'AddOrUpdate',
  props: ['isAddDialog', 'row', 'type', 'parentId', 'menuType'],
  components: { uploadFileOnly },
  data() {
    return {
      form: {
        activity: '',//菜单活动
        code: '',//菜单编码
        notes: '',//菜单说明
        icon: '',//菜单图标
        label: '',//菜单名称
        level: 0,//菜单级别(0.一级菜单,1.二级菜单等以此类推)
        path: '',//请求路径
        regionCode: '',//地区编码(多个以逗号隔开)
        sort: '',//排序
      },
      list: provinceList,
      productList: []
    }
  },
  created() {
    console.log(this.type, '_______', this.parentId)
    Object.assign(this.form, this.row)
    if (this.parentId) {
      this.form.level = 1
    }
    this.form.path = this.form.path.split(',')
    //菜单类型(0.移动端菜单,1.首页链接)
    this.form.type = this.menuType ? 1 : 0
    this.form.regionCode = this.form.regionCode ? this.form.regionCode.split(',') : []
    this.onload()
  },
  computed: {
    title(val) {
      let list = ['查看菜单详情', '添加菜单', '编辑菜单']
      return list[this.type]
    }
  },
  methods: {
    onload() {
      getProductConfigList({ isPage: 0 }).then(res => {
        let result = res.data || []
        this.productList = result.map(item => {
          return {
            label: item.name,
            value: item.path + '?productId=' + item.id
          }
        })
        console.log(this.productList);

      })
    },
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    onSubmit() {
      if (!this.$validate('form')) return
      let form = JSON.parse(JSON.stringify(this.form));
      form.regionCode = String(form.regionCode)
      form.parentId = this.parentId
      form.path = form.path.join(',')
      console.log(this.form);

      let success = res => {
        console.log(res)
        if (res.code != 200) {
          this.$message.error('保存失败')
          return
        }
        this.$message.success('保存成功')
        this.$emit('success')
        this.dialogClose()
      }
      if (form.id) {
        updateMobileMenu(form).then(success)
      } else {
        addMobileMenu(form).then(success)
      }
    },
  },
}
</script>
<style scoped>
.el-form-item {
  text-align: left;
}
.content-text {
  letter-spacing: 1px;
  color: #333333;
  margin: 0px 10px;
  font-size: 16px;
}
</style>