<template>
  <div>
    <el-form inline size="small">
      <el-form-item label="关键字">
        <el-input style="width:200px;" placeholder="请输入名称搜索" v-model="form.search"></el-input>
      </el-form-item>
      <el-form-item label="菜单级别">
        <el-select v-model="form.level" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option v-for="(item,i) in levelList" :key="i" :label="item" :value="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" icon="el-icon-plus" @click="showAdd(1)">新增菜单</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <el-col :span="4" style="height:calc(100vh - 150px);overflow:auto">
        <el-tree :data="dataTree" node-key="id" :props="defaultProps" default-expand-all :expand-on-click-node="false">
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <el-button type="text" size="mini" v-if="node.level==1" @click="changeTree(node)">配置二级菜单</el-button>
            <el-button type="text" size="mini" v-if="node.level==2" @click="changeTree(node)">配置三级菜单</el-button>
          </div>
        </el-tree>
      </el-col>
      <el-col :span="20">
        <el-table stripe :data="list" style="margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);" height="calc(100vh - 200px)">
          <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
          <el-table-column align="center" label="菜单名称">
            <template slot-scope="scope">
              <el-button type="text" @click="showAdd(0,scope.row)">{{scope.row.label}}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="code" align="center" label="菜单编码"></el-table-column>
          <el-table-column align="center" label="菜单图标">
            <template slot-scope="scope">
              <img :src="scope.row.icon" alt="" style="width:60px;height:60px" v-if="scope.row.icon">
            </template>
          </el-table-column>
          <el-table-column prop="notes" align="center" label="菜单说明"></el-table-column>
          <el-table-column prop="activity" align="center" label="菜单活动"></el-table-column>
          <el-table-column prop="level" align="center" label="菜单级别">
            <template slot-scope="scope">
              <span>{{levelList[scope.row.level]}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="path" align="center" label="菜单路径"></el-table-column>
          <el-table-column align="center" label="状态" width="70">
            <template slot-scope="scope">
              <el-tag size="mini" :type="!scope.row.isDisable ? 'primary' : 'warning'" disable-transitions>{{!scope.row.isDisable ?'已启用':'已禁用'}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" align="center" label="创建时间"></el-table-column>
          <el-table-column align="center" label="操作" width="280">
            <template slot-scope="scope">
              <el-button type="text" icon="el-icon-edit" @click="showAdd(2,scope.row)">编辑</el-button>
              <el-button type="text" icon="el-icon-remove-outline" style="color:red" @click="setDisable(scope.row.id,scope.row.isDisable)" v-if="scope.row.isDisable == 0">禁用</el-button>
              <el-button type="text" icon="el-icon-remove-outline" @click="setDisable(scope.row.id,scope.row.isDisable)" v-if="scope.row.isDisable == 1">启用</el-button>
              <el-button type="text" icon="el-icon-delete" style="color:red" @click="deleteOne(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="margin-top:10px;" layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total">
        </el-pagination>
      </el-col>
    </el-row>
    <AddOrUpdate v-if="isAddDialog" :isAddDialog.sync="isAddDialog" :row="editItem" :type="editType" @success="onload"></AddOrUpdate>
    <AddLevel v-if="showAddLevel" :show.sync="showAddLevel" :row="nodeItem" @success="onload"></AddLevel>
  </div>

</template>
<script>
import { getMobileMenuPage, getAllMobileMenuList, isDisable, deleteMobileMenu } from '@/api/appSetting'
import AddOrUpdate from './components/AddOrUpdate.vue'
import AddLevel from './components/AddLevel.vue'

export default {
  name: 'appSettingMenu',
  components: {
    AddOrUpdate, AddLevel
  },
  data() {
    return {
      // tree
      dataTree: [],
      showAddLevel: false,
      nodeItem: {},
      defaultProps: {
        children: 'childrenList',
        label: 'label'
      },
      // Dialog
      isAddDialog: false,
      editItem: '',
      editType: '',            // 编辑状态 1是详情，2是编辑,''是添加
      // search
      form: {
        search: '', //搜索
        level: '',
        pageNum: 1,//分页页数
        pageSize: 10,//每页显示多少, 
        type: 0
      },
      // list
      list: [],
      total: 0,//分页总页数
      levelList: ['一级菜单', '二级菜单', '三级菜单']
    }
  },
  created() {
    this.onload();
  },
  methods: {
    onload() {
      this.getList();
      this.getTree()
    },
    getList() {
      getMobileMenuPage(this.form).then((res) => {
        if (res.code == 200) {
          this.total = res.data.total;
          this.list = res.data.records;
        } else {
          this.$message.error({
            message: res.msg || res.error_msg,
            duration: 1500
          });
        }
      })
    },
    getTree() {
      getAllMobileMenuList(this.form).then((res) => {
        if (res.code == 200) {
          this.dataTree = res.data
          console.log(this.dataTree)
        } else {
          this.$message.error({
            message: res.msg || res.error_msg,
            duration: 1500
          });
        }
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getList();
    },
    changeTree(node) {
      console.log(node, 123)
      this.nodeItem = node.data
      this.showAddLevel = true
    },
    setDisable(id, type, index) {
      let param = {
        id
      }
      this.$confirm(Number(type) == 1 ? '是否启用该菜单?' : '是否禁用该菜单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        isDisable(param)
          .then((response) => {
            if (response.code == 200) {
              this.getList();
            }
            this.$message({
              type: response.code == 200 ? 'success' : 'default',
              duration: 2000,
              message: response.msg || response.error_msg,
            });
          })
      });
    },
    deleteOne(id) {
      this.$confirm('是否删除该菜单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteMobileMenu({ id }).then(res => {
          if (res.code != 200) {
            this.$message.error('删除失败')
            return
          }
          this.$message.success('删除成功')
          this.onload();
        })
      });
    },
    handleNodeClick(data) {
      this.orgId = data.id;
      this.handleCurrentChange(1);
    },
    // type == 0 查看详情 type == 1添加 type == 2 编辑
    showAdd(type, row) {
      this.editType = type;
      this.editItem = row;
      this.isAddDialog = true;
    }
  },
}
</script>
<style lang="scss" scoped>
.custom-tree-node {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;
}
</style>